import React, { Component } from 'react';

const _loaded = {};

class ImageLoader extends Component {
  
  //initial state: image loaded stage 
  state = {
    loaded: _loaded [
      // TODO: Or operator was just a guess, why does it work?!
      this.props.srcSet || this.props.src // Was just src 
    ]
  };

  //define our loading and loaded image classes
  static defaultProps = {
    className: "",
    loadingClassName: "img-loading",
    loadedClassName: "img-loaded"
  };

  //image onLoad handler to update state to loaded
  onLoad = () => {
    _loaded[
      // TODO: Or operator was just a guess, why does it work?!
      this.props.srcSet || this.props.src // Was just src
    ] = true;
    this.setState(() => ({ loaded: true }));
  };

  render() {
  
    let { className, loadedClassName, loadingClassName } = this.props;

    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return <img
      srcSet={this.props.srcSet}
      src={this.props.src}
      // onClick={this.props.onClick}
      className={className}
      onLoad={this.onLoad}
      alt={this.props.alt}
    />;
  }
}

export default ImageLoader;