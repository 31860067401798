// Will's React Lazy image loader component that finally works
// but took forever to make because he doesn't *really* understand React

import React, { Component } from 'react';
import classNames from 'classnames';
import LazyLoad from 'react-lazy-load';
import ImageLoader from './ImageLoader.js';

class ImageLazyLoad extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false
    }
  }

  render() {

    const calculatedPadding = ((this.props.ratioH / this.props.ratioW) * 100);
    
    const aspectRatio = {
      paddingBottom: calculatedPadding + "%",
    }

    return (
      
      <LazyLoad        
        debounce={false}
        offsetVertical={1000}
        onContentVisible={() => this.setState({ isLoaded: true }) }
        throttle={200} // 250ms is the default value of throttle, better performance, slower loading = higher numbers
      >

        <div 
          className={classNames(
            'image-wrap',
            {
              'is-loaded': this.state.isLoaded === true, 
              'no-fade': this.props.noFade === true, // Useful prop for images with a blend mode on a colour
            },
          )}
          style={aspectRatio}>
        
          <ImageLoader 
            srcSet=
              {this.props.src + "?nf_resize=fit&w=" + this.props.widthSm + " " + this.props.widthSm + "w," +
                this.props.src + "?nf_resize=fit&w=" + this.props.widthLg + " " + this.props.widthLg + "w"
              }
            src={this.props.src + "?nf_resize=fit&w=" + this.props.widthLg}
            alt={this.props.alt}
            className={this.props.className}
          />

          <span className="loader"></span>

        </div>

      </LazyLoad>
    );
  }
}

export default ImageLazyLoad;


