import React, { Component } from 'react';
import Plx from 'react-plx';

import Lottie from 'react-lottie';
import metro from '../animations/metro.json';

import LazyLoad from 'react-lazy-load';

class TokyoMetro extends Component {
  render() {

    const sideScroll = [
      {
        start: 'self',
        startOffset: 0,
        duration: 600,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: -10,
            endValue: -25,
            property: "translateX",
            unit: "%"
          }
          // ,
          // {
          //   startValue: 0,
          //   endValue: 1,
          //   property: "opacity"
          // }
        ]
      }
    ];

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: metro,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <section
        className="
          flex
          flex-wrap
          mb-16 lg:mb-32
          bg-white
        ">

        <LazyLoad
          className="w-full"
          height={'100%'}
          offset={3000}
        >
        {/* Maybe replace with a lottie anim? */}
        <figure 
          className="
            tokyo-metro
            relative
            w-full
            
            overflow-hidden
        ">
          <Plx
            className="plx"
            parallaxData={ sideScroll }
          >
            {/* <img 
              className=""
              src="./illustrations/tokyo-metro.svg"
              alt="An illustration of a salaryman commuter on his phone standing at the door of a moving carriage of the Tokyo metro."
            /> */}
            
              <Lottie
                options={defaultOptions}
                isStopped={false}
                isPaused={false}
                className="w-full"
              />
          </Plx>
        </figure>
        </LazyLoad>

        <div className="
          w-full
          h-10
          bg-black
        "></div>
      </section>
    )
  }
}
export default TokyoMetro;