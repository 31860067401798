import React, { Component } from 'react';
// import Image from "react-graceful-image";
import ImageLazyLoad from './ImageLazyLoad.js';

import posed, { } from 'react-pose';
// import { physics, spring } from 'popmotion';

// import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";

class Day0 extends Component {

  constructor(props) {
    super(props)
    this.state = { rotateCenter: false }
  }

  // Random image function
  randomize(imageArray) {
    return imageArray[Math.floor(Math.random() * imageArray.length)];
  }
  // Reload component and therefore random image
  handleButtonClick = ()=>{
    this.forceUpdate();
    // window.location.reload(false); // reloads whole page
    this.setState({ rotateCenter: true });
  }

  render() {
    // Simple array
    // const images = [
    //   './photos/DSCF7529.jpg', 
    //   './photos/DSCF7516.jpg',
    //   './photos/DSCF7416.jpg',
    //   './photos/DSCF7554.jpg',
    //   './photos/DSCF7592.jpg'
    // ];

    const ButtonDice = posed.button({
      // pressable: true,
      // init: { scale: 1 }, 
      //press: { rotate: 90 },
      // transition: { type: 'spring', stiffness: 30 }
    });

    // Object array
    const images = [
      { imageRef: './photos/DSCF7529.jpg', ratioW: 3, ratioH: 2, diceThrow: '⚀', caption: 'A guy on the phone staring longingly from a balcony' },
      { imageRef: './photos/DSCF7516.jpg', ratioW: 2, ratioH: 3,  diceThrow: '⚁', caption: 'A street crossing in Ueno' },
      { imageRef: './photos/DSCF7373.jpg', ratioW: 2, ratioH: 3,  diceThrow: '⚂', caption: 'Surfers in Kamakura' },
      { imageRef: './photos/DSCF7554.jpg', ratioW: 3, ratioH: 2,  diceThrow: '⚃', caption: 'A wall that looks like a surprised face' },
      { imageRef: './photos/DSCF7296.jpg', ratioW: 3, ratioH: 2,  diceThrow: '⚄', caption: 'Metro entrance stairs' },
      { imageRef: './photos/DSCF7108.jpg', ratioW: 3, ratioH: 2,  diceThrow: '⚅', caption: 'The lamp room at Teamlab Borderless exhibition' },
      // { imageRef:  './photos/DSCF7296.jpg', diceThrow: '⚅⚀', caption: 'A dog on a leash in Asakusa' },
      // { imageRef:  './photos/DSCF7416.jpg', diceThrow: '⚃⚃', caption: 'A photographer at the top of a ladder in Kamakura' },
      // { imageRef:  './photos/DSCF7256.jpg', diceThrow: '⚂⚅', caption: 'An electricity pole and building at dusk' },
    ];
    
    const randomImage = this.randomize(images);

    //const rotateCenter = this.state.rotateCenter

    return (

      <section 
        id="day-0"
        className="
          flex items-start
          mb-4 md:mb-8 lg:mb-16 xl:mb-32"
      >

        <div className="relative
                        w-full">
          
          <ButtonDice
            className=" 
              button-dice
              text-lg
              leading-none
              outline-none"
            onClick={this.handleButtonClick}
          >
            <span className="block -mt-1">{randomImage.diceThrow}</span>
          </ButtonDice>

          <ImageLazyLoad
            src={randomImage.imageRef}
            alt={randomImage.caption}
            widthLg={"2600"}
            widthSm={"1200"}
            ratioW={randomImage.ratioW}
            ratioH={randomImage.ratioH}
          />
        </div>

      </section>
    
    )
  }
}
export default Day0;