import React, { Component } from 'react';
import ResponsiveEmbed from "react-responsive-embed";

class Instagram extends Component {
  render() {
    return (
      <section className="
        flex flex-wrap
        py-4 md:py-8
        bg-black
      ">
        
        <div className="w-full md:w-1/2 lg:w-1/3 p-8">
          <ResponsiveEmbed 
            className=""
            src='https://www.youtube-nocookie.com/embed/soFz8cAhWoM?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&fs=0"'
            ratio='9:16'
          />
        </div>

        <div className="w-full md:w-1/2 lg:w-2/3
                        p-16 md:p-8
                        text-white
                        sm:col-count-1 md:col-count-1 lg:col-count-2 col-gap-lg">
          <p>Not exactly <a href="https://en.wikipedia.org/wiki/Slow_television">slow TV</a> – but arguably as boring, this is my japanese journey as seen through social media.</p>
          <p>Before the trip, I decided that I wouldn't share every detail on Instagram. It became quickly apparent, however, that there was just too much to see and talk about, so I lasted about two hours before caving in; deciding to share with my <em>close friend*</em> list only.</p>
          <p>The intention was to keep the project mostly under wraps until publishing this page, so here are all 37:55 minutes in their occasionally entertaining behind-the-scenes glory.</p>
          <p>Feel free to skip ahead, I know you have a busy day. If you persevere, you are likely to see a lot of food, some very passionate Beatles fans and possibly some useful tips for the next time you visit.</p>
          <p><br /><em>* If you didn't make the close friends list, please don't be offended — mostly useful for keeping the project a surprise from my colleagues <span role="img" aria-label="smiley">&#127773;</span></em></p>
        </div>

      </section>
    )
  }
}
export default Instagram;