// Shimokitazawa
import React, { Component } from 'react';
import ImageLazyLoad from './ImageLazyLoad.js';
import Plx from 'react-plx';

class Day4 extends Component {
  
  render() {

    const sectionIntro = [
      {
        start: 'self',
        startOffset: 0,
        duration: 500,
        easing: "easeOutQuad",
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    const sectionIntroSqueeze = [
      {
        start: 'self',
        startOffset: 0,
        duration: 300,
        easing: "easeInQuad",
        properties: [
          {
            startValue: 50,
            endValue: 0,
            property: "translateY"
          },
          {
            startValue: 0.85,
            endValue: 1,
            property: "scale"
          }
        ]
      }
    ];

    const shimokitazawaText = [
      {
        start: 'self',
        startOffset: 200,
        duration: 500,
        easing: "easeOutExpo",
        properties: [
          {
            startValue: 0.8,
            endValue: 1,
            property: "scaleX"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "scaleY"
          },
          {
            startValue: 0,
            endValue: 1,
            property: "opacity"
          }
        ]
      }
    ];

    return (
      
      <Plx
        id="day-4"
        className='
          md:mx-4
          pt-8 sm:pt-16 md:pt-24
          md:rounded-xxl lg:rounded-xxxxl
          bg-beige
          overflow-hidden'
        parallaxData={ sectionIntro }
        tagName='section'
      >
        
        <div
          className="
            flex flex-wrap
            px-4 xs:px-8 sm:px-16 md:px-24
        ">

          <header className=" flex w-full
                              px-4
                              mb-8">
            <div className="text-lg">
              <h2 className="font-bold">Day 4</h2>
              <em>Tuesday, 08 October</em>
            </div>
          </header>

          <div className="
            w-full
            px-4
            mb-16
            sm:col-count-2 lg:col-count-2 xl:col-count-3 col-gap-lg
          ">
            <p>Even in the morning, it's clear that Tokyoites are <em>tired by default</em> — commuters, at least. This is both interesting to observe and also a goldmine for someone looking to practice drawing people under time pressure and stealthily. I sit on the train to <a href="https://theculturetrip.com/asia/japan/articles/a-complete-neighbourhood-guide-to-shimokitazawa/" alt="Shimokitazawa on The Culture Trip">Shimokitazawa</a> armed with my iPad and continue working away while the oblivious subjects catch up on their sleep between stops.</p>
            <p>This neighbourhood on the outskirts of Tokyo, not far west of Shibuya is full of vintage shops and has an unquestionably cool atmosphere. I stop for a coffee at the reknowned <a href="http://www.alifewortheating.com/posts/tokyo/bear-pond" title="Bear Pond Espresso review">Bear Pond Espresso</a> (be there between 11-13) and explore the streets for the rest of the day.</p>
          </div>

          <div className="
            flex flex-wrap
            w-full
          ">

            <figure className="
              w-full sm:1/2 md:w-3/4 lg:w-2/5
              px-4
              mix-multiply
            ">
              <ImageLazyLoad
                src={"./drawings/day-4-1.jpg"}
                alt={"Train drawings, two people falling asleep"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"572"}
                ratioH={"299"}
                noFade={true}
              />
            </figure>

            <figure className="
              w-1/2 sm:w-2/5 md:w-1/4 lg:w-1/5
              px-4
              mb-4
              mix-multiply
            ">
              <ImageLazyLoad
                src={"./drawings/day-4-2.jpg"}
                alt={"Train drawing - a lady with a phone and antenna attachment"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"270"}
                ratioH={"483"}
                noFade={true}
              />
            </figure>
            
            <figure className="
              w-2/5 sm:w-1/3 lg:w-1/5
              pl-8 sm:px-8
              md:order-4 lg:order-3
              mix-multiply
            ">
              <ImageLazyLoad
                src={"./drawings/day-4-3-1.jpg"}
                alt={"Two people standing on the train"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"137"}
                ratioH={"353"}
                noFade={true}
              />
            </figure>

            <figure className="
              w-full md:w-2/3 lg:w-1/5
              sm:-mt-32 md:mt-0
              px-24 sm:pl-64 sm:pr-0 md:px-16 lg:px-0
              md:order-3 lg:order-4
              mix-multiply
            ">
              <ImageLazyLoad
                src={"./drawings/day-4-3.jpg"}
                alt={"A man sitting at a cafe checking his phone"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"348"}
                ratioH={"456"}
                noFade={true}
              />
            </figure>
          
          </div>

        </div>

        <h2 className=" w-full
                          mb-8
                          text-center font-sans
                          text-lg sm:text-xl lg:text-xxl
                          text-yellow
                          shimokitazawa
                          uppercase
                          ">
          <Plx
            className="block"
            parallaxData={ shimokitazawaText }
            tagName='span'
          >
            Shimokitazawa
          </Plx>
        </h2>

        <div
          className="
            flex flex-wrap
            px-4 xs:px-8 sm:px-16 md:px-24
            mb-16
        ">
          <div className="
            w-full lg:w-2/3
            px-4 md:px-8
            mb-8
          ">
            <figure className="mix-multiply">
              <ImageLazyLoad
                src={"./drawings/day-4-4.jpg"}
                alt={"Shit 10 second drawings of people walking past me in Shimokitazawa"}
                widthLg={"2600"}
                widthSm={"1200"}
                ratioW={"572"}
                ratioH={"435"}
                noFade={true}
              />
            </figure>
          </div>

          <div className="
            w-full
            lg:w-1/3
            px-4
            sm:col-count-2 md:col-count-1
          ">
            <p>One of the goals of this trip was to become more comfortable drawing and illustrating people. Shortly after consuming a particularly <a href="https://goo.gl/maps/zkEKjA3JGJrGp4R8A" title="Eight Burger's Tokyo">messy burger</a>, I decide to attempt 10-second sketches of people walking past me in a side street of Shimokitazawa.</p>
            <p>It's a seemingly futile exercise, but if any of them look at all better than others, they are probably the later sketches, after I'd trained my brain to observe, remember features, then sketch. Think I'll do this more to loosen up in future.</p>
          </div>
        
        </div>

        <Plx
          className='
            px-2
            transform-top-center'
          parallaxData={ sectionIntroSqueeze }
        >

          <div className="flex flex-wrap">

            <div className="w-full px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7174.jpg"}
                  alt={"Pot plants"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

            <div className="w-1/3 px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7139.jpg"}
                  alt={"Shimokitazawa passers by"}
                  widthLg={"1800"}
                  widthSm={"800"}
                  ratioW={"2"}
                  ratioH={"3"}
                />
              </figure>
            </div>

            <div className="w-1/3 px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7144.jpg"}
                  alt={"Shimokitazawa passers by"}
                  widthLg={"1800"}
                  widthSm={"800"}
                  ratioW={"2"}
                  ratioH={"3"}
                />
              </figure>
            </div>

            <div className="w-1/3 px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7146.jpg"}
                  alt={"Shimokitazawa passers by"}
                  widthLg={"1800"}
                  widthSm={"800"}
                  ratioW={"2"}
                  ratioH={"3"}
                />
              </figure>
            </div>

            <div className="w-1/2 px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7204.jpg"}
                  alt={"Shimokitazawa at night"}
                  widthLg={"1800"}
                  widthSm={"800"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

            <div className="w-1/2 px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7212.jpg"}
                  alt={"A guy eating in an Izakaya in Shimokitazawa"}
                  widthLg={"1800"}
                  widthSm={"800"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

            <div className="w-full px-2 mb-4">
              <figure className="overflow-hidden rounded-xl lg:rounded-xxxl">
                <ImageLazyLoad
                  src={"./photos/DSCF7221.jpg"}
                  alt={"Salarymen talking in a parking garage in Shimokitazawa"}
                  widthLg={"2600"}
                  widthSm={"1200"}
                  ratioW={"3"}
                  ratioH={"2"}
                />
              </figure>
            </div>

          </div>

        </Plx>

      </Plx>
    
    )
  }
}
export default Day4;