import React, { Component } from 'react';

// import PageHeaderTemp from './components/PageHeaderTemp';
// import Day0Temp from './components/Day0Temp';
// import PageFooterTemp from './components/PageFooterTemp';

// import PageNav from './components/PageNav';
import PageProgress from './components/PageProgress';
import PageHeader from './components/PageHeader';
import Day0 from './components/Day0';
import Day1 from './components/Day1';
import Day2 from './components/Day2';
import Day3 from './components/Day3';
import Day4 from './components/Day4';
import Day5 from './components/Day5';
import Day6 from './components/Day6';
import Day7 from './components/Day7';
import Day8 from './components/Day8';
import BonusDays from './components/BonusDays';
import Instagram from './components/Instagram';
import TokyoMetro from './components/TokyoMetro';
import PageFooter from './components/PageFooter';
class App extends Component {
  
  render() {

    return (
      
      <div className="app">
        
        {/* <PageHeaderTemp /> */}
        {/* <PageNav /> */}
        <PageProgress />
        <PageHeader />

        <main className="">
          {/* <Day0Temp /> */}
          <Day0 />
          <Day1 />
          <Day2 />
          <Day3 />
          <Day4 />
          <Day5 />
          <Day6 />
          <Day7 />
          <Day8 />
          <BonusDays />
          <Instagram />
          <TokyoMetro />
          <PageFooter />

          {/* <PageFooterTemp /> */}

        </main>

      </div>
      
    );
  }
}

export default App;
