import React, { Component } from 'react';
import Typed from 'react-typed';

// import ReactRotatingText from "react-rotating-text";

class PageHeader extends Component {

  render() {
    return (
      <header className="
        flex
        pt-4
        pb-32
      ">
        <div className="w-1/3 sm:w-2/3 px-4 pb-4">
          <h1 className="
            font-sans
            text-lg
            leading-none
          ">
          Eight<br />
          days<br />
          in<br />
          Tokyo
          </h1>
        </div>

        <aside className="
          w-2/3 sm:w-1/3 px-4">
          
          <Typed
            className="
              random-phrase
              text-lg
              leading-none
            "
            strings={[
              'a \n visual \n travel \n diary',
              'Asakusa \n Shibuya \n Shimokitazawa \n Ueno',
              'Jimbocho \n Yanaka \n Nezu \n Kamakura',
              '✈ \n ✎ \n 🌞 \n 🏁',
              'observations \n of \n city \n life',
              'details \n of \n a\n city',
              '🌝 \n 🌛\n 👀 \n 🌐',
              'a \n longform \n publishing \n experiment',
              'priviledged \n designer \n nerding \n out',
              'designer \n nerds \n out \n hard',
              'some \n thoughts \n on \n design',
              'a \n stream \n of \n consciousness',
              'creative \n process \n documented \n extensively',
              'some \n intricately \n designed \n environments',
              '100% \n unashamed \n design \n nerding',
              'Tokyo \n fascination \n explanation \n attempt',
              'architecture \n people \n systems \n transport',
              'digital \n scrapbook \n of \n stuff',
              'a \n very \n subjective \n report',
              'creative \n process \n excessively \n documented',
              'what \n is \n this \n anyway',
              'some \n unexpected \n surfing \n action', //unforeseen
              // 'reporting \n from \n best \n city',
              'basically \n looking \n at \n walls',
              'one \n page \n internet \n zine',
              'hope \n you \n like \n scrolling']}
            typeSpeed={40}
            backSpeed={30}
            backDelay={3000}
            contentType="html"
            shuffle={true}
            loop >
          </Typed>
            
          {/* <ReactRotatingText
            className="random-phrase"
            items={['a \n visual \n travel \n diary',
                    'Asakusa \n Shibuya \n Shimokitazawa \n Ueno',
                    'Jimbocho \n Yanaka \n Nezu \n Kamakura',
                    '✈ \n ✎ \n 🌞 \n 🏁',
                    'observations \n of \n city \n life',
                    'details \n of \n a\n city',
                    '🌝 \n 🌛\n 👀 \n 🌐 \n 🌐',
                    'a \n longform \n publishing \n experiment',
                    'priviledged \n designer \n nerding \n out',
                    'designer \n nerds \n out \n hard',
                    'some \n thoughts \n on \n design',
                    'a \n stream \n of \n consciousness',
                    'creative \n process \n documented \n extensively',
                    'some \n intricately \n designed \n environments',
                    '100% \n unashamed \n design \n nerding',
                    'Tokyo \n fascination \n explanation \n attempt',
                    'architecture \n people \n systems \n transport',
                    'digital \n scrapbook \n of \n stuff',
                    'a \n very \n subjective \n report',
                    'creative \n process \n excessively \n documented',
                    'what \n is \n this \n anyway',
                    'some \n unexpected \n surfing \n action', //unforeseen
                    // 'reporting \n from \n best \n city',
                    'basically \n looking \n at \n walls',
                    'one \n page \n internet \n zine',
                    'hope \n you \n like \n scrolling',
                  ]}
            pause={(2000)}
            emptyPause={(1000)}
            random
            // typingInterval={(40)}
            // deletingInterval={(25)}
          /> */}
        </aside>

      </header>
    )
  }
}
export default PageHeader;