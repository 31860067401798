import React, { Component } from 'react';
// import Image from "react-graceful-image";
import Plx from 'react-plx';

class Day8 extends Component {
  render() {

    const bgTransition = [
      {
        start: 'self',
        startOffset: 0,
        duration: 600,
        easing: "easeInExpo",
        properties: [
          {
            startValue: "#FF0000",
            endValue: "#000000",
            property: "backgroundColor"
          },
          {
            startValue: "#000000",
            endValue: "#FBB03B",
            property: "color"
          }
        ]
      }
    ];

    return (

      <Plx
        id="day-8"
        className='
          flex flex-wrap
          py-4 xs:py-8 sm:py-16 md:py-24
          px-4 xs:px-8 sm:px-16 md:px-24
          relative z-10'
        parallaxData={ bgTransition }
        tagName='section'
      >

        <header className="flex w-full">
          <div className="w-full p-4 text-lg">
            <h2 className="font-bold">Day 8 (Typhoon Hagibis hits Japan)</h2>
            <em>Saturday 12th October</em>
          </div>
        </header>

        <div className="w-full
                          mb-8
                          px-4
                          md:col-count-2 xl:col-count-3 col-gap-lg">
          <p>In the back of my mind from about day 4 is the looming threat of the typhoon which is supposed to hit Japan over the weekend. Sure enough, by Saturday, Hagibis makes landfall off the coast of Tokyo.</p>
          <p>I'm confined to my hotel room for 24 hours, with strong warnings not to venture outside do to the extremely high wind speeds.</p>
          <p>The hotel concierge tells me that <strong>a)</strong> the power is likely to go and <strong>b)</strong> the emergency stairs will be locked as they are on the outside of the building. I am on floor 10.</p>
          <p>I need not worry however - Tokyo remains relatively unscathed (I even felt a 5.6 magnitude earthquake that hit off the coast the same evening which didn't seem to make a dent on the news). Other parts of Japan, sadly, are not as lucky.</p>
          <p>I discover via notification at 01:30 that my morning flight has predictably been cancelled and that there will likely be travel chaos for at least a few days.</p>

          
        </div>

      </Plx>
    )
  }
}
export default Day8;