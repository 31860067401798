import React from 'react';
import ReactDOM from 'react-dom';

import './fonts/GT-Haptik-Bold-Oblique.eot';
import './fonts/GT-Haptik-Bold-Oblique.woff2';
import './fonts/GT-Haptik-Bold-Oblique.woff';
import './fonts/GT-Haptik-Bold-Oblique.ttf';

import './fonts/GT-Haptik-Bold-Rotalic.eot';
import './fonts/GT-Haptik-Bold-Rotalic.woff2';
import './fonts/GT-Haptik-Bold-Rotalic.woff';
import './fonts/GT-Haptik-Bold-Rotalic.ttf';

import './fonts/GT-Haptik-Bold.eot';
import './fonts/GT-Haptik-Bold.woff2';
import './fonts/GT-Haptik-Bold.woff';
import './fonts/GT-Haptik-Bold.ttf';

import './fonts/GT-Alpina-Regular.eot';
import './fonts/GT-Alpina-Regular.woff2';
import './fonts/GT-Alpina-Regular.woff';
import './fonts/GT-Alpina-Regular.ttf';

import './fonts/GT-Alpina-Regular-Italic.eot';
import './fonts/GT-Alpina-Regular-Italic.woff2';
import './fonts/GT-Alpina-Regular-Italic.woff';
import './fonts/GT-Alpina-Regular-Italic.ttf';

import './fonts/GT-Alpina-Bold.eot';
import './fonts/GT-Alpina-Bold.woff2';
import './fonts/GT-Alpina-Bold.woff';
import './fonts/GT-Alpina-Bold.ttf';

import './styles/tailwind.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
