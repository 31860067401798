// https://codepen.io/hakky/pen/XeNeqO

import React, { Component } from 'react';
import Plx from 'react-plx';

class PageProgress extends Component {

  componentDidMount() {
    const bar = document.getElementById('progress-bar');

    const scrollCalc = function() {
      const vheight = document.body.offsetHeight - window.innerHeight;
      const barWidth = (window.pageYOffset * 100 / vheight);
      bar.style.width = barWidth + '%';
    }

    scrollCalc();

    window.onscroll = function (e) {
      scrollCalc();
    }
    
  }
  
  render() {

    const colourChange = [
      {
        start: 'self',
        startOffset: 0,
        duration: "100%",
        easing: "easeInExpo",
        properties: [
          {
            startValue: "#000000",
            endValue: "#ff0000",
            property: "backgroundColor"
          }
        ]
      }
    ];

    return (

      <Plx
        parallaxData={ colourChange }
        id="progress-bar"
        className="fixed -top-3-5 left-0 h-4 z-50 origin-top-left transition-all">
      </Plx>

    )
  }
  
}
export default PageProgress;